import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useDocumentTitle = () => {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        let title = '';

        switch (path) {
            case '/home':
                title = 'Modern Development Support For Your IT Life | KHADIJA BIBI LLC';
                break;
            case '/products':
                title = 'KHADIJA BIBI LLC | Products';
                break;
            case '/product':
                title = 'KHADIJA BIBI LLC | Product';
                break;
            case '/about':
                title = 'KHADIJA BIBI LLC | About';
                break;
            case '/services':
                title = 'KHADIJA BIBI LLC | Services';
                break;
            case '/contact':
                title = 'KHADIJA BIBI LLC | Contact Us';
                break;
            default:
                title = 'Modern Development Support For Your IT Life | KHADIJA BIBI LLC'; // Fallback title
        }

        document.title = title;
    }, [location]);
};

export default useDocumentTitle;
